var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.displayLeaderboardReward
    ? _c(
        "v-card",
        { staticClass: "mb-2 asd", attrs: { width: "600" } },
        [
          _c("v-card-text", { staticClass: "info" }, [
            _c("b", [
              _vm._v(
                "Congratulations! You've reached " +
                  _vm._s(_vm.leaderboardPosition) +
                  " position in Season 2 !"
              ),
            ]),
            _c("br"),
            _vm._v(" You have won "),
            _c(
              "b",
              [
                _vm._v("["),
                _c("leaderboard-reward", {
                  staticClass: "d-inline-block",
                  attrs: { reward: _vm.leaderboardReward },
                }),
                _vm._v("]"),
              ],
              1
            ),
            _vm._v(
              ". The reward will be able to claim/distributed before Nov 8 2024. "
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }