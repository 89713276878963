var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "user-container d-block w-100 pt-7 pl-8 pr-10 pb-16" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-text-field", {
            staticClass: "pt-0 ml-0",
            attrs: {
              "append-icon": "mdi-pencil",
              "background-color": "frames",
              dense: "",
              readonly: "",
            },
            on: { click: _vm.openPopup, "click:append": _vm.openPopup },
            model: {
              value: _vm.account.username,
              callback: function ($$v) {
                _vm.$set(_vm.account, "username", $$v)
              },
              expression: "account.username",
            },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "mb-sm-0 mb-8",
              attrs: { cols: "6", order: "1", "order-sm": "0", sm: "3" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "black-border",
                  attrs: { color: "white", height: "80", width: "80" },
                  on: { click: _vm.openPopup },
                },
                [
                  _c("img", {
                    staticStyle: { width: "70px" },
                    attrs: {
                      src:
                        "/img/profile/avatars/" + _vm.account.avatar + ".jpg",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "mb-4 mb-sm-0",
              attrs: { cols: "12", order: "0", "order-sm": "1", sm: "6" },
            },
            [
              _c("wallet-balance-web3", { staticClass: "white--text" }),
              _c("wallet-balance-virtual", {
                staticClass: "brown--text text--darken-4",
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "mb-sm-0 mb-8",
              attrs: { cols: "6", order: "3", sm: "3" },
            },
            [!_vm.account.integrationName ? _c("qrcode-popup") : _vm._e()],
            1
          ),
        ],
        1
      ),
      _c(
        "dialog-box",
        {
          attrs: {
            model: _vm.popupOpen,
            title: `Update profile`,
            width: "800px",
          },
          on: {
            dialogClosed: function ($event) {
              _vm.popupOpen = !_vm.popupOpen
            },
          },
        },
        [
          _c(
            "v-form",
            {
              model: {
                value: _vm.isFormValid,
                callback: function ($$v) {
                  _vm.isFormValid = $$v
                },
                expression: "isFormValid",
              },
            },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("span", { staticClass: "title-to-left" }, [
                        _vm._v("Username"),
                      ]),
                      _c("v-text-field", {
                        staticClass: "mt-0 pt-0",
                        attrs: {
                          rules: _vm.usernameRules,
                          "clear-icon": "mdi-close-circle",
                          clearable: "",
                          disabled: _vm.account.integrationName,
                        },
                        on: {
                          "click:clear": function ($event) {
                            _vm.username = ""
                          },
                        },
                        model: {
                          value: _vm.username,
                          callback: function ($$v) {
                            _vm.username = $$v
                          },
                          expression: "username",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("span", { staticClass: "title-to-left" }, [_vm._v("Avatar")]),
              _c(
                "v-row",
                { staticClass: "avatars-list", attrs: { dense: "" } },
                [
                  0 === _vm.availableAvatars.length
                    ? _c(
                        "v-col",
                        {
                          staticClass: "d-flex justify-center",
                          attrs: { cols: "12" },
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              indeterminate: "",
                              color: "amber",
                              size: "100",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.availableAvatars, function (avatarId) {
                    return _c(
                      "v-col",
                      { key: "av" + avatarId, attrs: { cols: "3", sm: "2" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "choose-avatar-item",
                            class: { chosen: _vm.avatar === avatarId },
                            attrs: { "data-id": avatarId },
                            on: {
                              click: function ($event) {
                                return _vm.chooseAvatar(avatarId)
                              },
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src:
                                  "/img/profile/avatars/" + avatarId + ".jpg",
                              },
                            }),
                          ]
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        sm: "3",
                        offset: "0",
                        "offset-sm": "6",
                      },
                    },
                    [
                      _c(
                        "main-button",
                        {
                          staticClass: "d-none d-sm-block",
                          attrs: {
                            disabled: _vm.transactionInProgress,
                            "on-click": () => _vm.closePopup(),
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3" } },
                    [
                      _c(
                        "main-button",
                        {
                          attrs: {
                            disabled:
                              !_vm.isWalletConnected ||
                              !_vm.isFormValid ||
                              !_vm.isChangeMade,
                            loading: _vm.transactionInProgress,
                            "on-click": () => _vm.handleConfirmationClick(),
                          },
                        },
                        [_vm._v(" Save ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }