<template>
    <v-card v-if="displayLeaderboardReward" class="mb-2 asd" width="600">
        <v-card-text class="info">
            <b>Congratulations! You've reached {{ leaderboardPosition }} position in Season 2 !</b><br/>
            You have won <b>[<leaderboard-reward class="d-inline-block" :reward="leaderboardReward"/>]</b>.
            The reward will be able to claim/distributed before Nov 8 2024.
        </v-card-text>
    </v-card>
</template>

<script>

import {isDbDatePassed} from "@/helpers/timeHelper";
import {apiServiceVue} from "@/services/apiService.vue";
import LeaderboardReward from "@/components/leaderboard/leaderboard-reward";

export default {
    components: {LeaderboardReward},
    props: {
        account: Object,
    },
    data() {
        return {
            SCRL_USD: 69686, //1SCRL = $0.00001435
            SEASON_END_DATE: new Date(Date.UTC(2024, 10, 1, 12, 0, 0)), // Month starting from 0
            SEASON_REWARD_DIST_DATE: new Date(Date.UTC(2024, 10, 8, 12, 0, 0)),  // Month starting from 0
            leaderboardPosition: undefined,
            leaderboardReward: {},
        }
    },
    async mounted() {
        if (this.isTimeForLeaderboardRewardWindow && this.account && this.account.username) {
            this.getLeaderboardPosition();
        }
    },
    computed: {
        isTimeForLeaderboardRewardWindow() {
            return isDbDatePassed(this.SEASON_END_DATE) && !isDbDatePassed(this.SEASON_REWARD_DIST_DATE);
        },
        displayLeaderboardReward() {
            return this.isTimeForLeaderboardRewardWindow && this.leaderboardPosition && this.leaderboardPosition <= 100;
        },
        leaderboardRewardInSCRL() {
            return Math.round(this.leaderboardReward * this.SCRL_USD);
        }
    },
    methods: {
        getLeaderboardPosition() {
            const url = process.env.VUE_APP_APP_URL + 'leaderboard/season/current';
            apiServiceVue.get(url)
                .then(response => {
                    const leaderboardItems = response.data?.positions || [];
                    const leaderboardPosition = leaderboardItems.find((row) => row.account.username === this.account.username);
                    if (leaderboardPosition && leaderboardPosition.position <= 100) {
                        this.leaderboardPosition = leaderboardPosition.position;
                        this.leaderboardReward = leaderboardPosition.rewards[0];
                    }
                })
                .catch(e => {
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Error occurred'
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
