var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex justify-end w-100" },
    [
      _c(
        "v-btn",
        {
          staticClass: "darken-1 ml-2",
          attrs: { color: "frames", height: "80", ripple: "", width: "90" },
          on: { click: _vm.buttonDisplayQr },
        },
        [
          _c("div", { staticClass: "d-flex flex-column" }, [
            _c(
              "div",
              [
                _c("v-icon", { staticStyle: { "font-size": "45px" } }, [
                  _vm._v("mdi-qrcode"),
                ]),
              ],
              1
            ),
            _c("div", [_vm._v(" show QR ")]),
          ]),
        ]
      ),
      _c(
        "dialog-box",
        {
          attrs: {
            model: _vm.qrcodePopupOpen,
            title: "QR code to connect",
            width: "400",
          },
          on: {
            dialogClosed: function ($event) {
              _vm.qrcodePopupOpen = !_vm.qrcodePopupOpen
            },
          },
        },
        [
          _c("div", { staticClass: "d-flex flex-column align-center" }, [
            _c("p", { staticClass: "mt-2 mb-1" }, [
              _vm._v("Scan it by your phone:"),
            ]),
            _c("canvas", { attrs: { id: "qr-code" } }),
            _c("br"),
            _c("br"),
            _c("p", [_vm._v("Or use code:")]),
            _c(
              "span",
              { staticClass: "text--primary human-code ml-md-10" },
              [
                _vm._v(" " + _vm._s(_vm.qrCodeHumanized || _vm.qrCode) + " "),
                _c("copy-button", {
                  attrs: { text: _vm.qrCodeHumanized || _vm.qrCode },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }