<template>
    <v-container class="user-container d-block w-100 pt-7 pl-8 pr-10 pb-16">
        <v-row no-gutters>
            <v-text-field v-model="account.username"
                          append-icon="mdi-pencil"
                          background-color="frames"
                          class="pt-0 ml-0"
                          dense
                          readonly
                          @click="openPopup"
                          @click:append="openPopup"
            >
            </v-text-field>
        </v-row>
        <v-row no-gutters>
            <v-col class="mb-sm-0 mb-8" cols="6" order="1" order-sm="0" sm="3">
                <v-btn class="black-border" color="white" height="80" width="80" @click="openPopup">
                    <img :src="'/img/profile/avatars/'+account.avatar+'.jpg'" style="width:70px"/>
                </v-btn>
            </v-col>
            <v-col class="mb-4 mb-sm-0" cols="12" order="0" order-sm="1" sm="6">
                <wallet-balance-web3 class="white--text"></wallet-balance-web3>
                <wallet-balance-virtual class="brown--text text--darken-4"></wallet-balance-virtual>
            </v-col>
            <v-col class="mb-sm-0 mb-8" cols="6" order="3" sm="3">
                <qrcode-popup v-if="!account.integrationName"></qrcode-popup>
            </v-col>
        </v-row>

        <dialog-box
            :model="popupOpen"
            :title="`Update profile`"
            width="800px"
            v-on:dialogClosed="popupOpen=!popupOpen"
        >
            <v-form v-model="isFormValid">
                <v-row no-gutters>
                    <v-col cols="12" class="">
                        <span class="title-to-left">Username</span>
                        <v-text-field
                            v-model="username"
                            :rules="usernameRules"
                            class="mt-0 pt-0"
                            clear-icon="mdi-close-circle"
                            clearable
                            @click:clear="username=''"
                            :disabled="account.integrationName"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <span class="title-to-left">Avatar</span>
                <v-row dense class="avatars-list">
                    <v-col v-if="0 === availableAvatars.length" cols="12" class="d-flex justify-center">
                        <v-progress-circular
                            indeterminate
                            color="amber"
                            size="100"
                        ></v-progress-circular>
                    </v-col>
                    <v-col v-for="avatarId in availableAvatars" :key="'av'+avatarId" cols="3" sm="2">
                        <div @click="chooseAvatar(avatarId)" :data-id="avatarId" class="choose-avatar-item"
                             :class="{'chosen':avatar === avatarId}">
                            <img :src="'/img/profile/avatars/'+avatarId+'.jpg'"/>
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" sm="3" offset="0" offset-sm="6">
                        <main-button
                            :disabled="transactionInProgress"
                            :on-click="()=>closePopup()"
                            class="d-none d-sm-block"
                        >
                            Cancel
                        </main-button>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <main-button
                            :disabled="!isWalletConnected || !isFormValid || !isChangeMade"
                            :loading="transactionInProgress"
                            :on-click="()=>handleConfirmationClick()"
                            class=""
                        >
                            Save
                        </main-button>
                    </v-col>
                </v-row>
            </v-form>
        </dialog-box>
    </v-container>
</template>

<script>
import MainButton from "../ui/main-button";
import DialogBox from "../dialog-box";
import {apiServiceVue} from "../../services/apiService.vue";
import QrcodePopup from "./qrcode-popup";
import SignService from "@/services/contracts/signService";
import {UserWalletError} from "@/errors/WalletErrors";
import WalletBalanceWeb3 from "@/components/wallet-balance-web3";
import WalletBalanceVirtual from "@/components/wallet-balance-virtual";

export default {
    components: {WalletBalanceVirtual, WalletBalanceWeb3, QrcodePopup, DialogBox, MainButton},
    props: {
        account: Object
    },
    data() {
        return {
            transactionInProgress: false,
            popupOpen: false,
            isFormValid: false,
            username: null,
            avatar: null,
            availableAvatars: [],
            usernameRules: [
                v => v.length >= 5 || 'Too short (min. 5 characters)',
                v => v.length <= 30 || 'Too long',
                v => /^[A-Za-z0-9 ']+$/.test(v) || 'Use only alphanumeric characters'
            ],
        }
    },
    async mounted() {
        if (this.isWalletConnected) {
            this.init();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.init();
            });
        }
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        isChangeMade() {
            return this.account.username !== this.username || this.account.avatar !== this.avatar;
        }
    },
    methods: {
        init() {
            this.username = this.account.username;
            this.avatar = Number(this.account.avatar);
        },
        openPopup() {
            this.popupOpen = true;
            if (0 === this.availableAvatars.length) {
                this.getAvailableAvatars();
            }
        },
        closePopup() {
            this.popupOpen = false;
        },
        chooseAvatar(id) {
            this.avatar = Number(id);
        },
        handleConfirmationClick() {
            this.transactionInProgress = true;
            this.getUpdateNonce();
        },
        getAvailableAvatars() {
            const url = process.env.VUE_APP_ACCOUNT_URL + 'profile/avatar/available/' + this.web3.address;
            apiServiceVue.get(url)
                .then(response => {
                    this.availableAvatars = response.data;
                })
                .catch(e => {
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Fetch available avatars failed'
                    });
                })
                .finally(() => {
                });
        },
        getUpdateNonce() {
            const url = process.env.VUE_APP_ACCOUNT_URL + 'profile/nonce/new/' + this.web3.address;

            apiServiceVue.get(url)
                .then(response => {
                    if (response.data.hasOwnProperty('error')) {
                        this.flashMessage.show({
                            status: 'error',
                            message: response.error || 'Error occurred'
                        });
                        this.nickUpdateInProgress = false;
                    } else {
                        this.updateProfileRequest(response.data.nonce);
                    }
                })
                .catch(e => {
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Error occurred'
                    });
                })
                .finally(() => {
                });
        },
        async updateProfileRequest(nonce) {
            const signService = new SignService(this.web3);
            signService.signText(nonce)
                .then((signedOnce) => {
                    this.sendUpdateRequest(signedOnce)
                })
                .catch((error) => {
                    if (error instanceof UserWalletError) {
                        // do nothing
                    } else {
                        this.flashMessage.show({
                            status: 'error',
                            message: 'Sign error'
                        });
                        this.transferPopupOpen = false;
                    }
                    this.transactionInProgress = false;
                })
        },
        sendUpdateRequest(signedOnce) {
            const url = process.env.VUE_APP_ACCOUNT_URL + 'profile/update';
            apiServiceVue.post(url, {
                walletAddress: this.web3.address,
                hashedNOnce: signedOnce,
                username: this.username,
                avatar: this.avatar,
            })
                .then(response => {
                    this.flashMessage.show({
                        status: 'success',
                        message: 'Profile updated'
                    });
                    this.account.username = this.username;
                    this.account.avatar = Number(this.avatar);
                    this.$store.dispatch('fetchUser');
                })
                .catch(e => {
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Can not update profile: ' + e.response.data.error
                    });
                })
                .finally(() => {
                    this.transactionInProgress = false;
                    this.closePopup();
                });
        }
    },
};
</script>

<style lang="scss" scoped>
.title-to-left {
    margin-left: -10px;
}

.choose-avatar-item {
    cursor: pointer;

    &:hover {
        opacity: 0.5;
    }

    img {
        width: 100%;
    }

    &.chosen img {
        border: 2px solid var(--v-golden-base);
    }
}

.user-container {
    background-image: url("/img/profile/scroll.png");
    background-position: center;
    background-size: 100% 100%;

    ::v-deep .v-input input {
        padding-left: 8px;
    }

    ::v-deep .balance-indicator span,
    ::v-deep .balance-indicator button span {
        color: var(--v-white-base);
    }

    ::v-deep .balance-indicator {
        background-color: var(--v-frames-base);
        padding: 2px;
    }
}

.black-border {
    border: 3px solid black !important;
}
</style>
