<template>
    <div>
        <v-btn
            :disabled="isButtonDisabled"
            :loading="isButtonLoading"
            color="btnBg"
            large
            v-on:click="openDialog"
        >
            Withdraw
        </v-btn>
        <dialog-box
            :model="model.dialog"
            title="Withdraw"
            width="500px"
            v-on:dialogClosed="closeDialog"
        >
            <v-container>
                <v-row no-gutters>
                    <v-col cols="3">
                        <v-img aspect-ratio="1"
                               contain
                               max-width="64"
                               src="/img/tokens/v-scroll_128.png"
                        />
                    </v-col>
                    <v-col>
                        <v-text-field
                            :value="vBalance | localFormatNumber"
                            color="golden"
                            label="You will withdraw."
                            outlined
                            readonly
                            suffix="vSCRL"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="3">
                        <v-img aspect-ratio="1"
                               contain
                               max-width="64"
                               src="/img/scroll_128.png"
                        />
                    </v-col>
                    <v-col>
                        <v-text-field
                            :value="receiveAmount | localFormatNumber"
                            color="golden"
                            label="You will receive."
                            outlined
                            readonly
                            suffix="SCRL"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-alert
                            text
                            type="info"
                        >
                            <span>
                                Including {{ fetch.withdrawFee.value | localFormatNumber }} vSCRL fee.
                            </span>
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row v-if="model.message !== null" dense>
                    <v-col>
                        <v-alert
                            text
                            type="warning"
                        >
                            <span>
                                {{ model.message }}
                            </span>
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <main-button
                        :glow="true"
                        :loading="model.withdrawInProgress"
                        :on-click="withdrawVScrl"
                    >
                        Withdraw
                    </main-button>
                </v-row>
            </v-container>
        </dialog-box>
    </div>
</template>

<script>
import VirtualWalletService from "@/services/virtualWalletService";
import DialogBox from "@/components/dialog-box";
import BigNumber from "bignumber.js";
import MainButton from "@/components/ui/main-button";
import SignService from "@/services/contracts/signService";

export default {
    components: {MainButton, DialogBox},
    props: {
        loading: false,
        disabled: false
    },
    data() {
        return {
            service: {
                /** @type {VirtualWalletService} */
                virtualWallet: undefined
            },
            fetch: {
                openValidationInProgress: false,
                latestWithdraw: {
                    value: false,
                    loaded: false
                },
                withdrawFee: {
                    value: false,
                    loaded: false
                },
            },
            model: {
                dialog: false,
                withdrawAmount: 0,
                withdrawInProgress: false,
                message: null,
            }
        };
    },
    mounted() {
        this.service.virtualWallet = new VirtualWalletService();
    },
    destroyed() {
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        walletAddress() {
            return this.web3.address;
        },
        vBalance() {
            return this.$store.state.virtualWallet.balance;
        },
        isButtonLoading() {
            return this.loading
                || this.fetch.openValidationInProgress
        },
        isButtonDisabled() {
            return this.disabled || this.model.dialog;
        },
        currentAllTimeWithdrawAmount() {
            const fetchedWithdrawAmount = this.fetch.latestWithdraw.value.amountOfTotalWithdrawAllowance;
            return BigNumber(fetchedWithdrawAmount || 0).toNumber();
        },
        nextAllTimeWithdrawAmount() {
            return BigNumber(this.currentAllTimeWithdrawAmount)
                .plus(this.vBalance)
                .minus(this.fetch.withdrawFee.value)
                .toNumber()
        },
        receiveAmount() {
            return BigNumber(this.vBalance)
                .minus(this.fetch.withdrawFee.value)
                .toNumber();
        },

    },
    methods: {
        async openDialog() {
            try {
                this.$emit('buttonClick');
                this.model.withdrawInProgress = false;
                this.model.message = null;
                this.fetch.openValidationInProgress = true;
                const withdrawAvailable = await this.validateWithdrawsAvailable();
                const noWithdrawsPEnding = await this.validateNoPendingWithdraws();
                const enoughToPayFee = await this.validateEnoughToPayFee();
                await this.fetchWithdrawFee();
                await this.fetchLatestWithdraw();
                if (
                    withdrawAvailable
                    && noWithdrawsPEnding
                    && enoughToPayFee
                    && this.fetch.withdrawFee.loaded
                    && this.fetch.latestWithdraw.loaded
                ) {
                    this.model.dialog = true;
                }
            } finally {
                this.fetch.openValidationInProgress = false;
            }

        },
        closeDialog() {
            this.$emit('dialogClosed');
            this.model.withdrawInProgress = false;
            this.model.message = null;
            this.model.dialog = false;
            this.fetch.latestWithdraw.value = undefined;
            this.fetch.withdrawFee.value = undefined;
        },
        async validateWithdrawsAvailable() {
            const withdrawAvailable = await this.isWithdrawServiceAvailable();
            if (!withdrawAvailable) {
                this.flashMessage.show({
                    status: 'warning',
                    message: 'Withdraws are temporary unavailable',
                });
            }
            return withdrawAvailable;
        },
        async isWithdrawServiceAvailable() {
            return this.service.virtualWallet.getStatus()
                .then(response => {
                    return response.withdraws_active;
                })
        },
        async validateNoPendingWithdraws() {
            const anyWithdrawsOpen = await this.areAnyWithdrawsOpen()
            if (anyWithdrawsOpen) {
                this.flashMessage.show({
                    status: 'warning',
                    message: 'There is already pending withdraw for this account',
                });
            }
            return !anyWithdrawsOpen;
        },
        async validateEnoughToPayFee() {
            const fee = await this.service.virtualWallet.getWithdrawsFee().catch(e => {
                console.error(e);
                if (anyWithdrawsOpen) {
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Failed to validate fee',
                    });
                }
            })
            const enoughToPayFee = BigNumber(this.vBalance).isGreaterThan(fee);
            if (!enoughToPayFee) {
                this.flashMessage.show({
                    status: 'warning',
                    message: `Not enough vSCRL to pay for fee (${fee})`,
                });
            }
            return enoughToPayFee;
        },
        async areAnyWithdrawsOpen() {
            return this.service.virtualWallet.getWithdrawsInProgress(this.walletAddress)
                .then(response => {
                    return !!response.length;
                })
        },
        async fetchLatestWithdraw() {
            this.fetch.latestWithdraw.loaded = false;
            await this.service.virtualWallet.getLatestWithdraw(this.walletAddress)
                .then(response => {
                    this.fetch.latestWithdraw.value = response;
                    this.fetch.latestWithdraw.loaded = true;
                })
                .catch(e => {
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Fetch latest withdraw failed',
                    });
                })
        },
        async fetchWithdrawFee() {
            this.fetch.withdrawFee.loaded = false;
            await this.service.virtualWallet.getWithdrawsFee()
                .then(response => {
                    this.fetch.withdrawFee.value = response;
                    this.fetch.withdrawFee.loaded = true;
                })
                .catch(e => {
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Fetch withdraw fee failed',
                    });
                })
        },
        async withdrawVScrl() {
            this.model.withdrawInProgress = true;
            try {
                const nonce = await this.service.virtualWallet.getWithdrawsNonce(this.walletAddress);
                this.model.message = 'Sign nonce by web3 wallet to confirm your identity';
                const signedNonce = await new SignService(this.web3).signText(nonce);
                this.model.message = 'Request is being processed by server';
                await this.service.virtualWallet.openNewWithdraw(
                    this.walletAddress,
                    signedNonce,
                    this.currentAllTimeWithdrawAmount,
                    this.nextAllTimeWithdrawAmount
                )
                this.flashMessage.show({
                    status: 'success',
                    message: `Withdraw request submitted successfully`,
                });
            } catch (e) {
                console.error(e);
                this.flashMessage.show({
                    status: 'error',
                    message: `Error occurred during processing withdraw request`,
                });
            } finally {
                this.closeDialog();
            }
        },
    },
}
</script>

<style scoped>

</style>
