var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "profile" },
    [
      _c("page-filters-section", [
        _c("h1", { staticClass: "text-center" }, [_vm._v("User Profile")]),
      ]),
      !_vm.isWalletConnected
        ? _c(
            "v-row",
            { staticClass: "page-content pt-15" },
            [_c("connect-wallet")],
            1
          )
        : _vm._e(),
      _vm.isWalletConnected
        ? _c(
            "v-row",
            {
              staticClass:
                "page-content d-flex flex-column align-center justify-center",
            },
            [
              _c(
                "v-card",
                { staticClass: "mb-2", attrs: { width: "600" } },
                [
                  _vm.account && _vm.account.username
                    ? _c(
                        "v-card-text",
                        { staticClass: "py-2" },
                        [
                          _c("update-profile-popup", {
                            attrs: { account: _vm.account },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.account && _vm.account.username
                ? _c("leaderboard-reward-info", {
                    attrs: { account: _vm.account },
                  })
                : _vm._e(),
              _c(
                "v-card",
                { staticClass: "mb-16 pt-4", attrs: { width: "600" } },
                [
                  _vm.account && _vm.account.username
                    ? _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-tabs",
                                {
                                  staticClass: "mx-4 mb-6",
                                  attrs: { grow: "", "show-arrows": "" },
                                  model: {
                                    value: _vm.mainTab,
                                    callback: function ($$v) {
                                      _vm.mainTab = $$v
                                    },
                                    expression: "mainTab",
                                  },
                                },
                                [
                                  _c("v-tab", [_vm._v("Contests")]),
                                  _c("v-tab", [
                                    _c(
                                      "span",
                                      { staticClass: "hidden-sm-and-down" },
                                      [_vm._v("Statistics")]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "hidden-md-and-up" },
                                      [_vm._v("Stats")]
                                    ),
                                  ]),
                                  _c(
                                    "v-tab",
                                    { attrs: { disabled: !_vm.SHOW_V_WALLET } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "hidden-sm-and-down" },
                                        [_vm._v("Virtual Wallet")]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "hidden-md-and-up" },
                                        [_vm._v("V. Wallet")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-tabs-items",
                                {
                                  staticClass: "w-100 mx-4 mb-4",
                                  model: {
                                    value: _vm.mainTab,
                                    callback: function ($$v) {
                                      _vm.mainTab = $$v
                                    },
                                    expression: "mainTab",
                                  },
                                },
                                [
                                  _c(
                                    "v-tab-item",
                                    [
                                      _c(
                                        "v-card",
                                        { attrs: { flat: "" } },
                                        [
                                          _c(
                                            "v-container",
                                            { staticClass: "px-0 py-0" },
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { dense: "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c(
                                                        "v-expansion-panels",
                                                        [
                                                          _c(
                                                            "v-expansion-panel",
                                                            [
                                                              _c(
                                                                "v-expansion-panel-header",
                                                                [
                                                                  _vm._v(
                                                                    " Filters "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-expansion-panel-content",
                                                                [
                                                                  _c(
                                                                    "v-card",
                                                                    {
                                                                      staticClass:
                                                                        "filters-container",
                                                                      attrs: {
                                                                        color:
                                                                          "background",
                                                                        flat: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-card-text",
                                                                        [
                                                                          _c(
                                                                            "v-row",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  dense:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-col",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      cols: "12",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-autocomplete",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          items:
                                                                                            _vm.allStatuses,
                                                                                          "auto-select-first":
                                                                                            "",
                                                                                          "background-color":
                                                                                            "background",
                                                                                          clearable:
                                                                                            "",
                                                                                          color:
                                                                                            "golden",
                                                                                          filled:
                                                                                            "",
                                                                                          "full-width":
                                                                                            "",
                                                                                          "item-color":
                                                                                            "golden",
                                                                                          label:
                                                                                            "Filter by status",
                                                                                          multiple:
                                                                                            "",
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm.selectedStatuses,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.selectedStatuses =
                                                                                                $$v
                                                                                            },
                                                                                          expression:
                                                                                            "selectedStatuses",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "v-col",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      cols: "12",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-autocomplete",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          items:
                                                                                            _vm.allTags,
                                                                                          "auto-select-first":
                                                                                            "",
                                                                                          "background-color":
                                                                                            "background",
                                                                                          clearable:
                                                                                            "",
                                                                                          color:
                                                                                            "golden",
                                                                                          "deletable-chips":
                                                                                            "",
                                                                                          filled:
                                                                                            "",
                                                                                          "full-width":
                                                                                            "",
                                                                                          "item-color":
                                                                                            "golden",
                                                                                          label:
                                                                                            "Filter by tags",
                                                                                          multiple:
                                                                                            "",
                                                                                          "small-chips":
                                                                                            "",
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            _vm.selectedTags,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.selectedTags =
                                                                                                $$v
                                                                                            },
                                                                                          expression:
                                                                                            "selectedTags",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _c(
                                                                        "v-card-actions",
                                                                        {
                                                                          staticClass:
                                                                            "justify-end",
                                                                          staticStyle:
                                                                            {
                                                                              "margin-top":
                                                                                "-20px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  loading:
                                                                                    _vm.loadingContests,
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  _vm.searchContests,
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Search "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.loadingContests
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _c(
                                                            "h3",
                                                            {
                                                              staticClass:
                                                                "text-center",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "- Loading Contests -"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-progress-linear",
                                                            {
                                                              staticClass:
                                                                "my-4",
                                                              attrs: {
                                                                color: "frames",
                                                                height: "5px",
                                                                indeterminate:
                                                                  "",
                                                                rounded: "",
                                                              },
                                                            }
                                                          ),
                                                          _c(
                                                            "v-skeleton-loader",
                                                            {
                                                              attrs: {
                                                                type: "article, actions",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._l(
                                                        _vm.sortedContests,
                                                        function (contest, i) {
                                                          return _c(
                                                            "contest-participation-card",
                                                            {
                                                              key: `contest_card_${i}`,
                                                              attrs: {
                                                                "contest-dto":
                                                                  contest,
                                                                "statuses-to-display":
                                                                  _vm.statusFilters,
                                                              },
                                                            }
                                                          )
                                                        }
                                                      ),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tab-item",
                                    [
                                      _c(
                                        "v-card",
                                        { attrs: { flat: "" } },
                                        [
                                          _c(
                                            "v-card-text",
                                            {
                                              staticClass:
                                                "px-0 py-0 statistics-container",
                                            },
                                            [
                                              _c("v-data-table", {
                                                attrs: {
                                                  headers:
                                                    _vm.statisticsTableHeaders,
                                                  items: _vm.statisticsRows,
                                                  "items-per-page": 100,
                                                  "hide-default-footer": "",
                                                  "hide-default-header": "",
                                                  "mobile-breakpoint": "400",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "item.col2",
                                                      fn: function ({ item }) {
                                                        return [
                                                          "Disconnects/Surrenders" ===
                                                          item.col1
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "title",
                                                                      rawName:
                                                                        "v-title",
                                                                    },
                                                                  ],
                                                                  class: {
                                                                    "box-dc": true,
                                                                    "low-dc":
                                                                      item.col2 >
                                                                      5,
                                                                    "medium-dc":
                                                                      item.col2 >
                                                                      10,
                                                                    "high-dc":
                                                                      item.col2 >
                                                                      15,
                                                                    "very-high-dc":
                                                                      item.col2 >
                                                                      20,
                                                                  },
                                                                  attrs: {
                                                                    title:
                                                                      "The high ratio might result in <br/>permanent account ban",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item.col2
                                                                      ) +
                                                                      "% "
                                                                  ),
                                                                ]
                                                              )
                                                            : _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.col2
                                                                  )
                                                                ),
                                                              ]),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2160075498
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-tab-item",
                                    [
                                      _c(
                                        "v-card",
                                        { attrs: { flat: "" } },
                                        [_c("virtual-wallet-overview")],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }