var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { color: "cardBg lighten-1", outlined: "" } },
    [
      _c(
        "v-card-title",
        { staticClass: "cardBg lighten-2 rounded-b-xl mb-2" },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", { attrs: { cols: "7" } }, [
                _c("h5", [_vm._v("Total amount of your virtual scrolls:")]),
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("v-img", {
                      attrs: {
                        "aspect-ratio": "1",
                        contain: "",
                        "max-width": "48",
                        src: "/img/tokens/v-scroll_128.png",
                      },
                    }),
                    _c("span", { staticClass: "pl-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("localFormatNumber")(_vm.vBalance)) +
                          " vSCRL "
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
              _c(
                "v-col",
                { staticClass: "d-flex align-center justify-end" },
                [
                  _c("virtual-wallet-withdraw", {
                    attrs: {
                      disabled: _vm.alerts.length > 0,
                      loading: _vm.isWithdrawAvailabilityLoading,
                    },
                    on: {
                      buttonClick: _vm.onWithdrawClick,
                      dialogClosed: _vm.onWithdrawClose,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _vm.alerts.length
            ? _c(
                "v-alert",
                {
                  attrs: {
                    icon: false,
                    outlined: "",
                    text: "",
                    type: "warning",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-start" },
                    [
                      _c("v-icon", { attrs: { color: "orange", large: "" } }, [
                        _vm._v(" mdi-comment-alert"),
                      ]),
                      _c(
                        "ul",
                        _vm._l(_vm.alerts, function (alert, i) {
                          return _c("li", { key: "warn_" + i }, [
                            _vm._v(" " + _vm._s(alert.message) + " "),
                          ])
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                  _vm.parsedWithdrawData.length > 0
                    ? _c("v-data-table", {
                        attrs: {
                          headers: _vm.config.withdrawsHeaders,
                          items: _vm.parsedWithdrawData,
                          "items-per-page": 5,
                          loading: _vm.fetch.openWithdraws.loading,
                          dense: "",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            [
              _c("div", { staticClass: "pa-4 background rounded-t-lg w-100" }, [
                _vm._v(" Transaction history: "),
              ]),
              _c("v-data-table", {
                staticClass: "rounded-t-0",
                attrs: {
                  headers: _vm.config.historyHeaders,
                  items: _vm.parsedHistoryData,
                  "items-per-page": 10,
                  loading: _vm.fetch.history.loading,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }