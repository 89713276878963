<template>
    <v-col
        v-if="!isStatusLoading && shouldBeDisplayed"
        cols="12"
    >
        <v-card
            :class="{
                'theme-gray': [PARTICIPATION_STATUSES_DISPLAY.NOT_STARTED, PARTICIPATION_STATUSES_DISPLAY.CLAIMED].includes(contestStatus),
                'theme-green': [PARTICIPATION_STATUSES_DISPLAY.ACHIEVED, PARTICIPATION_STATUSES_DISPLAY.CLAIMABLE].includes(contestStatus),
                'theme-yellow': [PARTICIPATION_STATUSES_DISPLAY.IN_PROGRESS].includes(contestStatus),
                'theme-red': [PARTICIPATION_STATUSES_DISPLAY.FINISHED].includes(contestStatus),
            }"
            :img="`/img/profile/contest/background/${contestDto.background_name}.png`"
            class="card-outline my-2"
            outlined
        >
            <v-container class="card-shadow">
                <v-row dense>
                    <v-col cols="12">
                        <v-card-title class="text-break pb-0">
                            <div class="d-flex flex-row align-stretch justify-start">
                                <v-img
                                    :src="`img/profile/contest/icon/${contestDto.icon_name}.png`"
                                    class="rounded-lg"
                                    width="75px"
                                ></v-img>
                                <div class="d-flex flex-column align-start justify-start ml-4">
                                    <div class="themed subtitle-1 d-flex flex-row align-center">
                                        <v-icon class="mr-2 themed" small>mdi-circle</v-icon>
                                        <span v-if="contestStatus === PARTICIPATION_STATUSES_DISPLAY.NOT_STARTED">
                                        NOT STARTED
                                    </span>
                                        <span v-else-if="contestStatus === PARTICIPATION_STATUSES_DISPLAY.IN_PROGRESS">
                                        IN PROGRESS
                                    </span>
                                        <span v-else-if="contestStatus === PARTICIPATION_STATUSES_DISPLAY.ACHIEVED">
                                        ACHIEVED
                                    </span>
                                        <span v-else-if="contestStatus === PARTICIPATION_STATUSES_DISPLAY.CLAIMABLE">
                                        CLAIMABLE
                                    </span>
                                        <span v-else-if="contestStatus === PARTICIPATION_STATUSES_DISPLAY.CLAIMED">
                                        CLAIMED
                                    </span>
                                        <span v-else-if="contestStatus === PARTICIPATION_STATUSES_DISPLAY.FINISHED">
                                        FINISHED
                                    </span>
                                        <span v-else>
                                        UNKNOWN
                                    </span>
                                    </div>
                                    <h4>{{ contestDto.name }}</h4>
                                </div>
                            </div>
                        </v-card-title>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-row dense>
                        <v-col cols="12">
                            <v-divider class="themed"></v-divider>
                        </v-col>
                        <v-col class="d-flex flex-row align-center justify-start flex-wrap" cols="12">
                            <v-chip v-for="(tag) in contestTags"
                                    class="ma-1"
                                    color="buttonBg"
                                    small
                            >
                                {{ tag }}
                            </v-chip>
                        </v-col>
                        <v-col cols="12">
                            <v-divider class="themed"></v-divider>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="6">
                            <span>Terms:</span>
                            <ul>
                                <li
                                    v-for="(term, i) in contestDto.terms"
                                    :key="`competition_term_${i}`"
                                    class=""
                                >
                                    {{ term }}
                                </li>
                            </ul>
                        </v-col>
                        <v-col cols="6">
                            <span v-if="isContestEndedByDate || isContestEndedByConditions">Ended: </span>
                            <span v-else-if="isContestStarted">Ends: </span>
                            <span v-else>Starts: </span>
                            <ul>
                                <li v-if="isContestEndedByDate">
                                    {{ contestDto.end_timestamp | localDateTime }}
                                </li>
                                <li v-else-if="isContestEndedByConditions">
                                    Early end conditions met.
                                </li>
                                <li v-else-if="isContestStarted">
                                    {{ contestDto.end_timestamp | localDateTime }}
                                </li>
                                <li v-else>
                                    {{ contestDto.start_timestamp | localDateTime }}
                                </li>
                                <li
                                    v-for="(progress, i) in contestDto.end_progress"
                                    :key="`competition_term_${i}`"
                                    class=""
                                >
                                    {{ progress.name }}: {{
                                        Number(progress.progress).toLocaleString()
                                    }}/{{ Number(progress.target).toLocaleString() }}
                                    <v-progress-linear :value="progress.progress/progress.target*100"
                                                       class="themed"
                                                       color="white"
                                                       height="5"
                                                       rounded
                                    ></v-progress-linear>
                                </li>
                            </ul>
                        </v-col>
                        <v-col cols="12">
                            <v-divider class="themed"></v-divider>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col class="d-flex flex-column align-start justify-start" cols="12">
                            <div>
                                <span v-if="rewards.length > 1">Rewards:</span>
                                <span v-else>Reward:</span>
                            </div>
                            <div class="flex-grow-1 d-flex flex-row align-center justify-start flex-wrap">
                                <div
                                    v-for="(reward) in rewards"
                                    class="d-flex flex-row align-center justify-start mr-6">
                                    <div
                                        :class="reward.icon"
                                        class="mr-2 my-2">
                                    </div>
                                    <span>{{ reward.name }}</span>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-container>
            <v-container class="card-footer">
                <v-card-text>
                    <v-row>
                        <v-col v-for="(progress, i) in contestDto.competition_progress"
                               :key="`competition_term_${i}`"
                               class="py-1 mb-2"
                               cols="6"
                        >
                            <div class="mb-1">{{ progress.name }}: {{
                                    Number(progress.progress).toLocaleString()
                                }}/{{ Number(progress.target).toLocaleString() }}
                            </div>
                            <v-progress-linear :color="progressColor"
                                               :value="progress.progress/progress.target*100"
                                               height="5"
                            ></v-progress-linear>
                        </v-col>
                    </v-row>
                    <v-row class="mt-6" dense>
                        <v-col cols="12">
                            <v-divider class="themed"></v-divider>
                        </v-col>
                        <v-col class="d-flex align-center themed" cols="8">
                        <span v-if="isStatusLoading">
                            Checking claim status with blockchain
                        </span>
                            <span v-else-if="isClaimingInProgress">
                            Claiming in progress, check your wallet
                        </span>
                            <span v-else-if="contestStatus === PARTICIPATION_STATUSES_DISPLAY.NOT_STARTED">
                            Contest not started
                        </span>
                            <span v-else-if="contestStatus === PARTICIPATION_STATUSES_DISPLAY.IN_PROGRESS">
                            All requirements need to be finished
                        </span>
                            <span v-else-if="contestStatus === PARTICIPATION_STATUSES_DISPLAY.FINISHED">
                            Contest finished
                        </span>
                            <span v-else-if="contestStatus === PARTICIPATION_STATUSES_DISPLAY.ACHIEVED">
                            Wait till Tue to claim
                        </span>
                            <span v-else-if="contestStatus === PARTICIPATION_STATUSES_DISPLAY.CLAIMABLE">
                            Reward is waiting to be claimed
                        </span>
                            <span v-else-if="contestStatus === PARTICIPATION_STATUSES_DISPLAY.CLAIMED">
                            Reward already claimed
                        </span>
                        </v-col>
                        <v-col cols="4">
                            <main-button
                                :disabled="contestStatus !== PARTICIPATION_STATUSES_DISPLAY.CLAIMABLE"
                                :loading="isStatusLoading || isClaimingInProgress"
                                :on-click="claimReward"
                                glow
                            >
                            <span v-if="contestStatus === PARTICIPATION_STATUSES_DISPLAY.ACHIEVED"
                                  class="text-uppercase">
                                CLAIM IN
                                <timer :deadline-timestamp="blockchainUpdateTimestamp / 1000"></timer>
                            </span>
                                <span v-else>CLAIM</span>
                            </main-button>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-container>
        </v-card>
    </v-col>
</template>

<script>
import ContestParticipationDTO, {
    PARTICIPATION_STATUS_DISPLAY,
    PARTICIPATION_STATUS_INNER
} from "@/classes/contests/ContestParticipationDTO";
import MainButton from "@/components/ui/main-button";
import Timer from "@/components/timer";
import {BasicContestRewardContract} from "@/services/contracts/contests/basicContestRewardContract";
import {ContestRewardTypes} from "@/classes/contests/ContestHelperClasses";
import ContestRewardContractFactory from "@/services/contracts/contests/ContestRewardContractFactory";
import {WalletErrorHandler} from "@/errors/WalletErrors";

export default {
    components: {Timer, MainButton},
    props: {
        contestDto: {
            type: ContestParticipationDTO,
            required: true,
        },
        statusesToDisplay: {
            type: Array,
            default: () => Object.values(PARTICIPATION_STATUS_DISPLAY)
        }
    },
    data() {
        return {
            PARTICIPATION_STATUSES_INNER: PARTICIPATION_STATUS_INNER,
            PARTICIPATION_STATUSES_DISPLAY: PARTICIPATION_STATUS_DISPLAY,
            contestStatus: PARTICIPATION_STATUS_DISPLAY.NOT_STARTED,
            isStatusLoading: true,
            isClaimingInProgress: false,
        }
    },
    mounted() {
        if (this.isWalletConnected) {
            this.syncContestStatus();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.syncContestStatus();
            });
        }
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        walletAddress() {
            return this.web3.address;
        },
        isContractAddressValid() {
            return Boolean(this.contestDto.contract_address.length === 42)
        },
        claimRewardContract() {
            return this.isContractAddressValid
                ? ContestRewardContractFactory.getContract(
                    this.contestDto.contract_type,
                    this.contestDto.contract_address,
                    this.web3
                )
                : {};
        },
        isContestStarted() {
            return Date.now() > new Date(this.contestDto.start_timestamp)
        },
        isContestEndedByDate() {
            return Date.now() > new Date(this.contestDto.end_timestamp)
        },
        isContestEndedByConditions() {
            return this.contestDto
                    ?.end_progress
                    ?.some(progress => progress.progress > progress.target)
                || false;
        },
        blockchainUpdateTimestamp() {
            const UPDATE_DAY = 2;   // Tue
            const UPDATE_HOUR = 11; // 11:00 UTC

            const today = new Date();

            const updateDayOffset = UPDATE_DAY - today.getUTCDay()
            const closestUpdateDate = new Date(
                Date.UTC(
                    today.getUTCFullYear(),
                    today.getUTCMonth(),
                    today.getUTCDate() + updateDayOffset,
                    UPDATE_HOUR
                )
            );

            if (Date.now() < closestUpdateDate) {
                return closestUpdateDate.getTime();
            } else {
                return closestUpdateDate.setDate(closestUpdateDate.getDate() + 7);
            }

        },
        contestTags() {
            return this.contestDto.tags;
        },
        rewards() {
            return this.contestDto
                .rewards
                .map(r => ({
                    name: r.name,
                    icon: this.getIconClassByReward(r.type),
                }));
        },
        progressColor() {
            switch (true) {
                case [this.PARTICIPATION_STATUSES_DISPLAY.NOT_STARTED, this.PARTICIPATION_STATUSES_DISPLAY.CLAIMED].includes(this.contestStatus):
                    return 'grey';
                case [this.PARTICIPATION_STATUSES_DISPLAY.ACHIEVED, this.PARTICIPATION_STATUSES_DISPLAY.CLAIMABLE].includes(this.contestStatus):
                    return 'green'
                case [this.PARTICIPATION_STATUSES_DISPLAY.IN_PROGRESS].includes(this.contestStatus):
                    return 'yellow'
                case [this.PARTICIPATION_STATUSES_DISPLAY.FINISHED].includes(this.contestStatus):
                    return 'red'
                default:
                    return 'white';
            }
        },
        shouldBeDisplayed() {
            return this.statusesToDisplay.includes(this.contestStatus);
        },
    },
    methods: {
        /**
         *
         * @param {ContestRewardTypes} reward
         */
        getIconClassByReward(reward) {
            switch (reward) {
                case ContestRewardTypes.CARD:
                    return 'icon_card'
                case ContestRewardTypes.WIZARD:
                    return 'icon_wizard'
                case ContestRewardTypes.LAND:
                    return 'icon_land'
                case ContestRewardTypes.ENHANCEMENT:
                    return 'icon_bag'
            }
        },
        async syncContestStatus() {
            this.isStatusLoading = true;
            switch (this.contestDto.status) {
                case this.PARTICIPATION_STATUSES_INNER.PENDING:
                    this.contestStatus = this.PARTICIPATION_STATUSES_DISPLAY.NOT_STARTED;
                    break;
                case this.PARTICIPATION_STATUSES_INNER.IN_PROGRESS:
                    this.contestStatus = this.PARTICIPATION_STATUSES_DISPLAY.IN_PROGRESS;
                    break;
                case this.PARTICIPATION_STATUSES_INNER.ENDED:
                    this.contestStatus = this.PARTICIPATION_STATUSES_DISPLAY.FINISHED;
                    break;
                case this.PARTICIPATION_STATUSES_INNER.ACHIEVED:
                    if (this.isContractAddressValid) {
                        const {exists, claimed} = await this.claimRewardContract
                            .getClaimStatus(this.walletAddress, this.contestDto.id);
                        if (!exists && !claimed) {
                            this.contestStatus = this.PARTICIPATION_STATUSES_DISPLAY.ACHIEVED;
                        } else if (exists && !claimed) {
                            this.contestStatus = this.PARTICIPATION_STATUSES_DISPLAY.CLAIMABLE;
                        } else if (claimed) {
                            this.contestStatus = this.PARTICIPATION_STATUSES_DISPLAY.CLAIMED;
                        }
                    } else {
                        this.contestStatus = this.PARTICIPATION_STATUSES_DISPLAY.ACHIEVED;
                    }

                    break;
            }
            this.isStatusLoading = false;
        },
        async claimReward() {
            this.isClaimingInProgress = true;
            this.claimRewardContract.claim(this.contestDto.id)
                .then(() => {
                    this.contestStatus = this.PARTICIPATION_STATUSES_DISPLAY.CLAIMED;
                    this.flashMessage.show({
                        status: 'success',
                        message: `Reward for contest ${this.contestDto.name} claimed.`
                    });
                })
                .catch(e => {
                    this.flashMessage.show({
                        status: 'error',
                        message: `Error during claim for ${this.contestDto.name} reward.`
                    });
                    WalletErrorHandler.handleTxError(e);
                })
                .finally(_ => {
                    this.isClaimingInProgress = false;
                });
        },
    },
}
</script>

<style lang="scss" scoped>
.card-outline {
    border-radius: 8px;
    border-style: solid;
    border-width: 2px;
}

.card-shadow {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 6px 6px 0 0 !important;
}

.card-footer {
    background-color: rgba(0, 0, 0, 0.90);
    border-radius: 0 0 6px 6px !important;
}


.theme-gray {
    border-color: #c9c9c9;

    .themed {
        color: #c9c9c9;
        border-color: #c9c9c9;
    }
}

.theme-green {
    border-color: #7fc73f;

    .themed {
        color: #7fc73f;
        border-color: #7fc73f;
    }
}

.theme-yellow {
    border-color: #fdc403;

    .themed {
        color: #fdc403;
        border-color: #fdc403;
    }
}

.theme-red {
    border-color: #fd3f3e;

    .themed {
        color: #fd3f3e;
        border-color: #fd3f3e;
    }
}

//ToDo: Extract icons to common file ↓↓↓ (Chris help?)

.icon_card {
    width: 15px;
    height: 21px;
    background: url('/img/icons/common-icons.png') -80px -10px;
}

.icon_wizard {
    width: 15px;
    height: 20px;
    background: url('/img/icons/common-icons.png') -115px -51px;
}

.icon_land {
    width: 15px;
    height: 21px;
    background: url('/img/icons/common-icons.png') -10px -54px;
}

.icon_bag {
    width: 15px;
    height: 18px;
    background: url('/img/icons/common-icons.png') -10px -95px;
}
</style>
