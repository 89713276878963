var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isStatusLoading && _vm.shouldBeDisplayed
    ? _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "v-card",
            {
              staticClass: "card-outline my-2",
              class: {
                "theme-gray": [
                  _vm.PARTICIPATION_STATUSES_DISPLAY.NOT_STARTED,
                  _vm.PARTICIPATION_STATUSES_DISPLAY.CLAIMED,
                ].includes(_vm.contestStatus),
                "theme-green": [
                  _vm.PARTICIPATION_STATUSES_DISPLAY.ACHIEVED,
                  _vm.PARTICIPATION_STATUSES_DISPLAY.CLAIMABLE,
                ].includes(_vm.contestStatus),
                "theme-yellow": [
                  _vm.PARTICIPATION_STATUSES_DISPLAY.IN_PROGRESS,
                ].includes(_vm.contestStatus),
                "theme-red": [
                  _vm.PARTICIPATION_STATUSES_DISPLAY.FINISHED,
                ].includes(_vm.contestStatus),
              },
              attrs: {
                img: `/img/profile/contest/background/${_vm.contestDto.background_name}.png`,
                outlined: "",
              },
            },
            [
              _c(
                "v-container",
                { staticClass: "card-shadow" },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "text-break pb-0" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-row align-stretch justify-start",
                                },
                                [
                                  _c("v-img", {
                                    staticClass: "rounded-lg",
                                    attrs: {
                                      src: `img/profile/contest/icon/${_vm.contestDto.icon_name}.png`,
                                      width: "75px",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-column align-start justify-start ml-4",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "themed subtitle-1 d-flex flex-row align-center",
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-2 themed",
                                              attrs: { small: "" },
                                            },
                                            [_vm._v("mdi-circle")]
                                          ),
                                          _vm.contestStatus ===
                                          _vm.PARTICIPATION_STATUSES_DISPLAY
                                            .NOT_STARTED
                                            ? _c("span", [
                                                _vm._v(" NOT STARTED "),
                                              ])
                                            : _vm.contestStatus ===
                                              _vm.PARTICIPATION_STATUSES_DISPLAY
                                                .IN_PROGRESS
                                            ? _c("span", [
                                                _vm._v(" IN PROGRESS "),
                                              ])
                                            : _vm.contestStatus ===
                                              _vm.PARTICIPATION_STATUSES_DISPLAY
                                                .ACHIEVED
                                            ? _c("span", [_vm._v(" ACHIEVED ")])
                                            : _vm.contestStatus ===
                                              _vm.PARTICIPATION_STATUSES_DISPLAY
                                                .CLAIMABLE
                                            ? _c("span", [
                                                _vm._v(" CLAIMABLE "),
                                              ])
                                            : _vm.contestStatus ===
                                              _vm.PARTICIPATION_STATUSES_DISPLAY
                                                .CLAIMED
                                            ? _c("span", [_vm._v(" CLAIMED ")])
                                            : _vm.contestStatus ===
                                              _vm.PARTICIPATION_STATUSES_DISPLAY
                                                .FINISHED
                                            ? _c("span", [_vm._v(" FINISHED ")])
                                            : _c("span", [_vm._v(" UNKNOWN ")]),
                                        ],
                                        1
                                      ),
                                      _c("h4", [
                                        _vm._v(_vm._s(_vm.contestDto.name)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("v-divider", { staticClass: "themed" })],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "d-flex flex-row align-center justify-start flex-wrap",
                              attrs: { cols: "12" },
                            },
                            _vm._l(_vm.contestTags, function (tag) {
                              return _c(
                                "v-chip",
                                {
                                  staticClass: "ma-1",
                                  attrs: { color: "buttonBg", small: "" },
                                },
                                [_vm._v(" " + _vm._s(tag) + " ")]
                              )
                            }),
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("v-divider", { staticClass: "themed" })],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c("v-col", { attrs: { cols: "6" } }, [
                            _c("span", [_vm._v("Terms:")]),
                            _c(
                              "ul",
                              _vm._l(_vm.contestDto.terms, function (term, i) {
                                return _c(
                                  "li",
                                  { key: `competition_term_${i}` },
                                  [_vm._v(" " + _vm._s(term) + " ")]
                                )
                              }),
                              0
                            ),
                          ]),
                          _c("v-col", { attrs: { cols: "6" } }, [
                            _vm.isContestEndedByDate ||
                            _vm.isContestEndedByConditions
                              ? _c("span", [_vm._v("Ended: ")])
                              : _vm.isContestStarted
                              ? _c("span", [_vm._v("Ends: ")])
                              : _c("span", [_vm._v("Starts: ")]),
                            _c(
                              "ul",
                              [
                                _vm.isContestEndedByDate
                                  ? _c("li", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("localDateTime")(
                                              _vm.contestDto.end_timestamp
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm.isContestEndedByConditions
                                  ? _c("li", [
                                      _vm._v(" Early end conditions met. "),
                                    ])
                                  : _vm.isContestStarted
                                  ? _c("li", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("localDateTime")(
                                              _vm.contestDto.end_timestamp
                                            )
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("li", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("localDateTime")(
                                              _vm.contestDto.start_timestamp
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]),
                                _vm._l(
                                  _vm.contestDto.end_progress,
                                  function (progress, i) {
                                    return _c(
                                      "li",
                                      { key: `competition_term_${i}` },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(progress.name) +
                                            ": " +
                                            _vm._s(
                                              Number(
                                                progress.progress
                                              ).toLocaleString()
                                            ) +
                                            "/" +
                                            _vm._s(
                                              Number(
                                                progress.target
                                              ).toLocaleString()
                                            ) +
                                            " "
                                        ),
                                        _c("v-progress-linear", {
                                          staticClass: "themed",
                                          attrs: {
                                            value:
                                              (progress.progress /
                                                progress.target) *
                                              100,
                                            color: "white",
                                            height: "5",
                                            rounded: "",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("v-divider", { staticClass: "themed" })],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "d-flex flex-column align-start justify-start",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("div", [
                                _vm.rewards.length > 1
                                  ? _c("span", [_vm._v("Rewards:")])
                                  : _c("span", [_vm._v("Reward:")]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex-grow-1 d-flex flex-row align-center justify-start flex-wrap",
                                },
                                _vm._l(_vm.rewards, function (reward) {
                                  return _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-row align-center justify-start mr-6",
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "mr-2 my-2",
                                        class: reward.icon,
                                      }),
                                      _c("span", [_vm._v(_vm._s(reward.name))]),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-container",
                { staticClass: "card-footer" },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        _vm._l(
                          _vm.contestDto.competition_progress,
                          function (progress, i) {
                            return _c(
                              "v-col",
                              {
                                key: `competition_term_${i}`,
                                staticClass: "py-1 mb-2",
                                attrs: { cols: "6" },
                              },
                              [
                                _c("div", { staticClass: "mb-1" }, [
                                  _vm._v(
                                    _vm._s(progress.name) +
                                      ": " +
                                      _vm._s(
                                        Number(
                                          progress.progress
                                        ).toLocaleString()
                                      ) +
                                      "/" +
                                      _vm._s(
                                        Number(progress.target).toLocaleString()
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("v-progress-linear", {
                                  attrs: {
                                    color: _vm.progressColor,
                                    value:
                                      (progress.progress / progress.target) *
                                      100,
                                    height: "5",
                                  },
                                }),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-6", attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [_c("v-divider", { staticClass: "themed" })],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex align-center themed",
                              attrs: { cols: "8" },
                            },
                            [
                              _vm.isStatusLoading
                                ? _c("span", [
                                    _vm._v(
                                      " Checking claim status with blockchain "
                                    ),
                                  ])
                                : _vm.isClaimingInProgress
                                ? _c("span", [
                                    _vm._v(
                                      " Claiming in progress, check your wallet "
                                    ),
                                  ])
                                : _vm.contestStatus ===
                                  _vm.PARTICIPATION_STATUSES_DISPLAY.NOT_STARTED
                                ? _c("span", [_vm._v(" Contest not started ")])
                                : _vm.contestStatus ===
                                  _vm.PARTICIPATION_STATUSES_DISPLAY.IN_PROGRESS
                                ? _c("span", [
                                    _vm._v(
                                      " All requirements need to be finished "
                                    ),
                                  ])
                                : _vm.contestStatus ===
                                  _vm.PARTICIPATION_STATUSES_DISPLAY.FINISHED
                                ? _c("span", [_vm._v(" Contest finished ")])
                                : _vm.contestStatus ===
                                  _vm.PARTICIPATION_STATUSES_DISPLAY.ACHIEVED
                                ? _c("span", [
                                    _vm._v(" Wait till Tue to claim "),
                                  ])
                                : _vm.contestStatus ===
                                  _vm.PARTICIPATION_STATUSES_DISPLAY.CLAIMABLE
                                ? _c("span", [
                                    _vm._v(" Reward is waiting to be claimed "),
                                  ])
                                : _vm.contestStatus ===
                                  _vm.PARTICIPATION_STATUSES_DISPLAY.CLAIMED
                                ? _c("span", [
                                    _vm._v(" Reward already claimed "),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c(
                                "main-button",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.contestStatus !==
                                      _vm.PARTICIPATION_STATUSES_DISPLAY
                                        .CLAIMABLE,
                                    loading:
                                      _vm.isStatusLoading ||
                                      _vm.isClaimingInProgress,
                                    "on-click": _vm.claimReward,
                                    glow: "",
                                  },
                                },
                                [
                                  _vm.contestStatus ===
                                  _vm.PARTICIPATION_STATUSES_DISPLAY.ACHIEVED
                                    ? _c(
                                        "span",
                                        { staticClass: "text-uppercase" },
                                        [
                                          _vm._v(" CLAIM IN "),
                                          _c("timer", {
                                            attrs: {
                                              "deadline-timestamp":
                                                _vm.blockchainUpdateTimestamp /
                                                1000,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c("span", [_vm._v("CLAIM")]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }