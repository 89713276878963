<template>
    <v-card
        color="cardBg lighten-1"
        outlined
    >
        <v-card-title class="cardBg lighten-2 rounded-b-xl mb-2">
            <v-row dense>
                <v-col cols="7">
                    <h5>Total amount of your virtual scrolls:</h5>
                    <div class="d-flex align-center">
                        <v-img aspect-ratio="1"
                               contain
                               max-width="48"
                               src="/img/tokens/v-scroll_128.png"
                        />
                        <span class="pl-2">
                                {{ vBalance | localFormatNumber }} vSCRL
                            </span>
                    </div>
                </v-col>
                <v-col class="d-flex align-center justify-end">
                    <virtual-wallet-withdraw
                        v-on:buttonClick="onWithdrawClick"
                        v-on:dialogClosed="onWithdrawClose"
                        :disabled="alerts.length > 0"
                        :loading="isWithdrawAvailabilityLoading"
                    >
                    </virtual-wallet-withdraw>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-alert
                v-if="alerts.length"
                :icon="false"
                outlined
                text
                type="warning"
            >
                <div class="d-flex align-start">
                    <v-icon color="orange" large> mdi-comment-alert</v-icon>
                    <ul>
                        <li v-for="(alert, i) in alerts" :key="'warn_'+i">
                            {{ alert.message }}
                        </li>
                    </ul>
                </div>
                <v-data-table
                    v-if="parsedWithdrawData.length > 0"
                    :headers="config.withdrawsHeaders"
                    :items="parsedWithdrawData"
                    :items-per-page="5"
                    :loading="fetch.openWithdraws.loading"
                    dense
                >
                </v-data-table>
            </v-alert>
            <div>
                <div
                    class="pa-4 background rounded-t-lg w-100"
                >
                    Transaction history:
                </div>
                <v-data-table
                    :headers="config.historyHeaders"
                    :items="parsedHistoryData"
                    :items-per-page="10"
                    :loading="fetch.history.loading"
                    class="rounded-t-0"
                >
                </v-data-table>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import VirtualWalletService from "@/services/virtualWalletService";
import BigNumber from "bignumber.js";
import WalletBalanceVirtual from "@/components/wallet-balance-virtual";
import MainButton from "@/components/ui/main-button";
import VirtualWalletWithdraw from "@/components/virtual-wallet/virtual-wallet-withdraw";

export default {
    components: {VirtualWalletWithdraw, MainButton, WalletBalanceVirtual},
    props: {},
    data() {
        return {
            service: {
                /** @type {VirtualWalletService} */
                virtualWallet: undefined
            },
            fetch: {
                interval: undefined,
                withdrawAvailable: {
                    value: false,
                    loading: true,
                    loaded: false
                },
                fee: {
                    value: false,
                    loading: true,
                    loaded: false
                },
                openWithdraws: {
                    value: [],
                    loading: true,
                    loaded: false
                },
                history: {
                    value: [],
                    loading: true,
                    loaded: false
                }
            },
            config: {
                historyHeaders: [
                    {text: 'Number', value: 'number', sortable: false, divider: true, align: 'end'},
                    {text: 'Date', value: 'date', sortable: false},
                    {text: 'Type ', value: 'type', sortable: false},
                    {text: 'Amount', value: 'amount', sortable: false, align: 'end'},
                ],
                withdrawsHeaders: [
                    {text: 'Id', value: 'id', sortable: false},
                    {text: 'Update Date', value: 'update_date', sortable: false},
                    {text: 'Status', value: 'status', sortable: false},
                    {text: 'Amount', value: 'amount', sortable: false, align: 'end'},
                ],
                reasonTableToText: {
                    'game_matches': 'Game Reward',
                    'accounts': 'Account Connection',
                    'virtual_wallet_web3_withdraws': 'vScrl Withdraw',
                    'virtual_wallet_special_rewards': 'Special Reward',
                },
                withdrawStatusToText: {
                    CREATED: 'Processing by server',
                    TRANSACTION_SUBMITTED: 'Processing by blockchain',
                    TRANSACTION_CONFIRMED: 'Confirmed by blockchain',
                    TRANSACTION_ERROR: 'Error occurred',
                },
                operationToText: {
                    'DEC': '-',
                    'INC': '+',

                },
            },
        }
    },
    mounted() {
        this.service.virtualWallet = new VirtualWalletService();
        if (this.isWalletConnected) {
            this.init();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.init();
            });
        }
    },
    destroyed() {
        clearInterval(this.fetch.interval);
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        walletAddress() {
            return this.web3.address;
        },
        vBalance() {
            return this.$store.state.virtualWallet.balance;
        },
        isWithdrawAvailabilityLoading() {
            return !this.fetch.withdrawAvailable.loaded
                || (this.fetch.openWithdraws.loading && !this.fetch.openWithdraws.loaded)
                || !this.fetch.fee.loaded
        },
        alerts() {
            const checks = [
                {
                    rule: this.fetch.withdrawAvailable.loaded && !(this.fetch.withdrawAvailable?.value === true),
                    message: 'Withdraws are temporary unavailable.',
                },
                {
                    rule: this.fetch.openWithdraws.loaded && this.fetch.openWithdraws.value.length,
                    message: `You have pending withdraw${this.fetch.openWithdraws.value.length ? '' : 's'}, wait for them to finish.`,
                },
                {
                    rule: this.fetch.fee.loaded && BigNumber(this.vBalance).isLessThan(this.fetch.fee.value),
                    message: `Not enough vSCRL to pay for fee. Need: ${this.fetch.fee.value} vSCRL`,
                },
                {
                    rule: this.$store.state.userAccount.restrictions.includes('VirtualWalletWithdraw'),
                    message: `Your account has been banned. You are not allowed to withdraw vSCRLs`,
                },
            ];
            return checks.filter(check => check.rule);
        },
        parsedHistoryData() {
            return this.fetch.history.value.map((value, index) => {
                const {amount, operation, reason_table, created_at} = value;
                const parsedIndex = index + 1;
                const parsedDate = this.$options.filters.localDateTime(created_at);
                const parsedType = this.config.reasonTableToText[reason_table];

                const parsedAmountNumber = this.$options.filters.localFormatNumber(amount);
                const parsedAmount = this.config.operationToText[operation] + parsedAmountNumber + ' vSCRL';

                return {
                    number: parsedIndex,
                    date: parsedDate,
                    type: parsedType,
                    amount: parsedAmount,
                };
            }).sort((a, b) => b.number - a.number)
        },
        parsedWithdrawData() {
            return this.fetch.openWithdraws.value.map((value, index) => {
                const {id, amountAlreadyWithdrawn, amountOfTotalWithdrawAllowance, updated_at, status} = value;
                const parsedIndex = index + 1;
                const parsedId = '#' + id
                const parsedDate = this.$options.filters.localDateTime(updated_at);
                const parsedStatus = this.config.withdrawStatusToText[status];

                const amountNumber = BigNumber(amountOfTotalWithdrawAllowance).minus(BigNumber(amountAlreadyWithdrawn)).toNumber();
                const parsedAmountNumber = this.$options.filters.localFormatNumber(amountNumber);
                const parsedAmount = parsedAmountNumber + ' vSCRL';

                return {
                    number: parsedIndex,
                    id: parsedId,
                    update_date: parsedDate,
                    amount: parsedAmount,
                    status: parsedStatus,
                };
            })
        },
    },
    methods: {
        init() {
            this.updateData();
            this.fetch.interval = setInterval(() => {
                this.updateData();
            }, 60 * 1000)   //1 min
        },
        updateData() {
            this.fetchStatus();
            this.fetchFee();
            this.fetchHistory();
            this.fetchOpenWithdraws();
            this.$store.dispatch('updateWeb3Balance');
            this.$store.dispatch('updateVirtualBalance');
        },
        onWithdrawClick() {
            this.updateData();
        },
        onWithdrawClose() {
            this.updateData();
        },
        fetchStatus() {
            this.fetch.withdrawAvailable.loading = true;
            this.service.virtualWallet.getStatus()
                .then(response => {
                    this.fetch.withdrawAvailable.value = response.withdraws_active;
                    this.fetch.withdrawAvailable.loaded = true;
                })
                .catch(e => {
                    this.fetch.withdrawAvailable.value = false;
                    this.fetch.withdrawAvailable.loaded = false;
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Fetch virtual wallet status failed',
                    });
                })
                .finally(_ => {
                    this.fetch.withdrawAvailable.loading = false;
                })
        },
        fetchFee() {
            this.fetch.fee.loading = true;
            this.service.virtualWallet.getWithdrawsFee()
                .then(response => {
                    this.fetch.fee.value = response;
                    this.fetch.fee.loaded = true;
                })
                .catch(e => {
                    this.fetch.fee.loaded = false;
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Fetch withdraw fee status failed',
                    });
                })
                .finally(_ => {
                    this.fetch.fee.loading = false;
                })
        },
        fetchHistory() {
            this.fetch.history.loading = true;
            this.service.virtualWallet.getTransactionsHistory(this.walletAddress)
                .then(response => {
                    this.fetch.history.value = response;
                    this.fetch.history.loaded = true;
                })
                .catch(e => {
                    this.fetch.history.value = false;
                    this.fetch.history.loaded = false;
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Fetch virtual wallet history failed',
                    });
                })
                .finally(_ => {
                    this.fetch.history.loading = false;
                })
        },
        fetchOpenWithdraws() {
            this.fetch.openWithdraws.loading = true;
            this.service.virtualWallet.getWithdrawsInProgress(this.walletAddress)
                .then(response => {
                    this.fetch.openWithdraws.value = response;
                    this.fetch.openWithdraws.loaded = true;
                })
                .catch(e => {
                    this.fetch.openWithdraws.value = false;
                    this.fetch.openWithdraws.loaded = false;
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Fetch virtual wallet open withdraws failed',
                    });
                })
                .finally(_ => {
                    this.fetch.openWithdraws.loading = false;
                })
        },
    },
}
</script>

<style scoped>

</style>
