var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          attrs: {
            disabled: _vm.isButtonDisabled,
            loading: _vm.isButtonLoading,
            color: "btnBg",
            large: "",
          },
          on: { click: _vm.openDialog },
        },
        [_vm._v(" Withdraw ")]
      ),
      _c(
        "dialog-box",
        {
          attrs: { model: _vm.model.dialog, title: "Withdraw", width: "500px" },
          on: { dialogClosed: _vm.closeDialog },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-img", {
                        attrs: {
                          "aspect-ratio": "1",
                          contain: "",
                          "max-width": "64",
                          src: "/img/tokens/v-scroll_128.png",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          value: _vm._f("localFormatNumber")(_vm.vBalance),
                          color: "golden",
                          label: "You will withdraw.",
                          outlined: "",
                          readonly: "",
                          suffix: "vSCRL",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "3" } },
                    [
                      _c("v-img", {
                        attrs: {
                          "aspect-ratio": "1",
                          contain: "",
                          "max-width": "64",
                          src: "/img/scroll_128.png",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: {
                          value: _vm._f("localFormatNumber")(_vm.receiveAmount),
                          color: "golden",
                          label: "You will receive.",
                          outlined: "",
                          readonly: "",
                          suffix: "SCRL",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-alert", { attrs: { text: "", type: "info" } }, [
                        _c("span", [
                          _vm._v(
                            " Including " +
                              _vm._s(
                                _vm._f("localFormatNumber")(
                                  _vm.fetch.withdrawFee.value
                                )
                              ) +
                              " vSCRL fee. "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.model.message !== null
                ? _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-alert",
                            { attrs: { text: "", type: "warning" } },
                            [
                              _c("span", [
                                _vm._v(" " + _vm._s(_vm.model.message) + " "),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c(
                    "main-button",
                    {
                      attrs: {
                        glow: true,
                        loading: _vm.model.withdrawInProgress,
                        "on-click": _vm.withdrawVScrl,
                      },
                    },
                    [_vm._v(" Withdraw ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }