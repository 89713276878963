import {AbstractContestRewardContract} from "@/services/contracts/contests/abstractContestRewardContract";

export class BasicContestRewardContract extends AbstractContestRewardContract {
    // @formatter:off
    static contractAddress;
    static contractAbi = [{"inputs":[],"name":"claim","outputs":[],"stateMutability":"nonpayable","type":"function"},{"inputs":[{"internalType":"address","name":"_address","type":"address"}],"name":"claimStatus","outputs":[{"components":[{"internalType":"bool","name":"exists","type":"bool"},{"internalType":"bool","name":"claimed","type":"bool"}],"internalType":"structClaimCardReward.RewardStatus","name":"","type":"tuple"}],"stateMutability":"view","type":"function"}];
    // @formatter:on

    constructor(web3, contractAddress) {
        super(web3);
        this.constructor.contractAddress = contractAddress;
    }

    async getClaimStatus(walletAddress, _) {
        const contract = await this.getInitializedContract();
        return await contract.methods.claimStatus(walletAddress).call();
    }

    async claim(_) {
        const contract = await this.getInitializedContract();
        return await contract.methods.claim().send(await this._sendParams());
    }
}

