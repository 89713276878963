export default class ContestParticipationDTO {
    /** @type {String | Number} */
    id;
    /** @type {String} */
    name;
    /** @type {String} */
    icon_name;
    /** @type {String} */
    background_name;
    /** @type {String[]} */
    terms;
    /** @type {String|Number} */
    start_timestamp;
    /** @type {String|Number} */
    end_timestamp;
    /** @type {ContestProgress[]} */
    end_progress;
    /** @type {ContestProgress[]} */
    competition_progress;
    /** @type {PARTICIPATION_STATUS_INNER} */
    status;
    /** @type {String} */
    contract_address;
    /** @type {String[]} */
    tags;
    /** @type {ContestRewardDefinition[]} */
    rewards;
    /** @type {String} */
    contract_type;
}

export class ContestProgress {

    /**
     *
     * @param {String} name
     * @param {Number} target
     * @param {Number} progress
     */
    constructor(name, target, progress) {
        this.name = name;
        this.target = target;
        this.progress = progress;
    }

    /** @type {String} */
    name;
    /** @type {Number} */
    target;
    /** @type {Number} */
    progress;
}

export const PARTICIPATION_STATUS_INNER = Object.freeze({
    PENDING: 'PENDING',
    IN_PROGRESS: 'IN_PROGRESS',
    ENDED: 'ENDED',
    ACHIEVED: 'ACHIEVED',
    SYNCING: 'SYNCING',
    CLAIMABLE: 'CLAIMABLE',
    CLAIMED: 'CLAIMED',
});

export const PARTICIPATION_STATUS_DISPLAY = Object.freeze({
    NOT_STARTED: 'NOT_STARTED',
    IN_PROGRESS: 'IN_PROGRESS',
    ACHIEVED: 'ACHIEVED',
    CLAIMABLE: 'CLAIMABLE',
    CLAIMED: 'CLAIMED',
    FINISHED: 'FINISHED',
});

