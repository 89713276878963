import {BasicContestRewardContract} from "@/services/contracts/contests/basicContestRewardContract";
import {GiveawayContestRewardContract} from "@/services/contracts/contests/giveawayContestRewardContract";

export default class ContestRewardContractFactory {
    /**
     * @type {{GIVEAWAY: GiveawayContestRewardContract, BASIC: BasicContestRewardContract}}
     */
    static #CONTEST_REWARD_CONTRACTS = {
        BASIC: BasicContestRewardContract,
        GIVEAWAY: GiveawayContestRewardContract,
    }

    /**
     *
     * @param contractType
     * @param contractAddress
     * @param web3
     * @returns {AbstractContestRewardContract}
     */
    static getContract(contractType, contractAddress, web3) {
        const contractClass = this.#CONTEST_REWARD_CONTRACTS[contractType];
        return new contractClass(web3, contractAddress);
    }
};
