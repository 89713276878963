<template>
    <div class="d-flex justify-end w-100">
        <v-btn
            @click="buttonDisplayQr"
            class="darken-1 ml-2" color="frames"
            height="80"
            ripple
            width="90">
            <div class="d-flex flex-column">
                <div>
                    <v-icon style="font-size:45px;">mdi-qrcode</v-icon>
                </div>
                <div>
                    show QR
                </div>
            </div>
        </v-btn>

        <dialog-box
            :model="qrcodePopupOpen"
            v-on:dialogClosed="qrcodePopupOpen=!qrcodePopupOpen"
            title="QR code to connect"
            width="400"
        >
            <div class="d-flex flex-column align-center">
                <p class="mt-2 mb-1">Scan it by your phone:</p>
                <canvas id="qr-code"></canvas>
                <br/><br/>
                <p>Or use code:</p>
                <span class="text--primary human-code ml-md-10">
                    {{ qrCodeHumanized || qrCode }}
                    <copy-button :text="qrCodeHumanized || qrCode"></copy-button>
                </span>
            </div>
        </dialog-box>
    </div>
</template>

<script>

import DialogBox from "../dialog-box";
import QRCode from "qrcode";
import CopyButton from "../ui/copy-button";
import {apiServiceVue} from "@/services/apiService.vue";
import SignService from "@/services/contracts/signService";
import {UserWalletError} from "@/errors/WalletErrors";

export default {
    components: {CopyButton, DialogBox},
    props: {},
    data() {
        return {
            qrcodePopupOpen: false,
            qrCode: null,
            qrCodeHumanized: null,
        }
    },
    async mounted() {
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        }
    },
    methods: {
        buttonDisplayQr(){
            this.getDisplayQrNonce();
        },
        getDisplayQrNonce() {
            const url = process.env.VUE_APP_ACCOUNT_URL + 'qrcode/nonce/new/' + this.web3.address;

            apiServiceVue.get(url)
                .then(response => {
                    if (response.data.hasOwnProperty('error')) {
                        this.flashMessage.show({
                            status: 'error',
                            message: response.error || 'Error occurred'
                        });
                    } else {
                        this.displayQrRequest(response.data.nonce);
                    }
                })
                .catch(e => {
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Error occurred'
                    });
                })
                .finally(() => {
                });
        },
        async displayQrRequest(nonce) {
            const signService = new SignService(this.web3);
            signService.signText(nonce)
                .then((signedOnce) => {
                    this.sendUpdateRequest(signedOnce)
                })
                .catch((error) => {
                    if (error instanceof UserWalletError) {
                        // do nothing
                    } else {
                        this.flashMessage.show({
                            status: 'error',
                            message: 'Sign error'
                        });
                        this.transferPopupOpen = false;
                    }
                    this.transactionInProgress = false;
                })
        },
        sendUpdateRequest(signedOnce) {
            const url = process.env.VUE_APP_ACCOUNT_URL + 'qrcode/display';
            apiServiceVue.post(url, {
                walletAddress: this.web3.address,
                hashedNOnce: signedOnce,
            })
                .then(response => {
                    this.openQrCode(response.data.qr_code);
                })
                .catch(e => {
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Can not display QR dode: ' + e.response.data.error
                    });
                })
                .finally(() => {
                    this.transactionInProgress = false;
                });
        },
        async openQrCode(qrCode) {
            this.qrcodePopupOpen = true;
            this.qrCode = qrCode;
            this.qrCodeHumanized = qrCode.replace(/.{3}/g, '$& ');
            setTimeout(() => this.generateQrCode(qrCode), 500); // to inject image properly
        },
        generateQrCode(loginCode) {
            //https://github.com/soldair/node-qrcode
            let canvas = document.getElementById('qr-code');
            QRCode.toCanvas(canvas, loginCode, {
                scale: 7,
                margin: 3,
                errorCorrectionLevel: 'M'
            }, function (error) {
                if (error) {
                    throw new Error(error);
                }
            })
        },
    },
};
</script>

<style lang="scss" scoped>
p {
    font-size: 120%;
}

.human-code {
    font-size: 180%;
    margin: -10px 0 0 0;
}

.pointer {
    cursor: pointer;
}
</style>
