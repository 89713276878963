<template>
    <v-container class="profile">
        <page-filters-section>
            <h1 class="text-center">User Profile</h1>
        </page-filters-section>

        <v-row v-if="!isWalletConnected" class="page-content pt-15">
            <connect-wallet></connect-wallet>
        </v-row>

        <v-row v-if="isWalletConnected" class="page-content d-flex flex-column align-center justify-center">
            <v-card class="mb-2" width="600">
                <v-card-text v-if="account && account.username" class="py-2">
                    <update-profile-popup :account="account"></update-profile-popup>
                </v-card-text>
            </v-card>

            <leaderboard-reward-info v-if="account && account.username" :account="account"></leaderboard-reward-info>

            <v-card class="mb-16 pt-4" width="600">
                <v-card-text v-if="account && account.username">
                    <v-row>
                        <v-tabs v-model="mainTab" class="mx-4 mb-6" grow show-arrows>
                            <v-tab>Contests</v-tab>
                            <v-tab>
                                <span class="hidden-sm-and-down">Statistics</span>
                                <span class="hidden-md-and-up">Stats</span>
                            </v-tab>
                            <v-tab :disabled="!SHOW_V_WALLET">
                                <span class="hidden-sm-and-down">Virtual Wallet</span>
                                <span class="hidden-md-and-up">V. Wallet</span>
                            </v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="mainTab" class="w-100 mx-4 mb-4">
                            <v-tab-item>
                                <v-card flat>
                                    <v-container class="px-0 py-0">
                                        <v-row dense>
                                            <v-col cols="12">
                                                <v-expansion-panels>
                                                    <v-expansion-panel>
                                                        <v-expansion-panel-header>
                                                            Filters
                                                        </v-expansion-panel-header>
                                                        <v-expansion-panel-content>
                                                            <v-card
                                                                class="filters-container"
                                                                color="background"
                                                                flat
                                                            >
                                                                <v-card-text>
                                                                    <v-row dense>
                                                                        <v-col cols="12">
                                                                            <v-autocomplete
                                                                                v-model="selectedStatuses"
                                                                                :items="allStatuses"
                                                                                auto-select-first
                                                                                background-color="background"
                                                                                clearable
                                                                                color="golden"
                                                                                filled
                                                                                full-width
                                                                                item-color="golden"
                                                                                label="Filter by status"
                                                                                multiple
                                                                            ></v-autocomplete>
                                                                        </v-col>
                                                                        <v-col cols="12">
                                                                            <v-autocomplete
                                                                                v-model="selectedTags"
                                                                                :items="allTags"
                                                                                auto-select-first
                                                                                background-color="background"
                                                                                clearable
                                                                                color="golden"
                                                                                deletable-chips
                                                                                filled
                                                                                full-width
                                                                                item-color="golden"
                                                                                label="Filter by tags"
                                                                                multiple
                                                                                small-chips
                                                                            ></v-autocomplete>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-card-text>
                                                                <v-card-actions class="justify-end"
                                                                                style="margin-top:-20px">
                                                                    <v-btn
                                                                        :loading="loadingContests"
                                                                        @click="searchContests"
                                                                    >
                                                                        Search
                                                                    </v-btn>
                                                                </v-card-actions>
                                                            </v-card>
                                                        </v-expansion-panel-content>
                                                    </v-expansion-panel>
                                                </v-expansion-panels>
                                            </v-col>

                                            <v-col
                                                v-if="loadingContests"
                                                cols="12">
                                                <h3 class="text-center">- Loading Contests -</h3>
                                                <v-progress-linear
                                                    class="my-4"
                                                    color="frames"
                                                    height="5px"
                                                    indeterminate
                                                    rounded
                                                ></v-progress-linear>
                                                <v-skeleton-loader
                                                    type="article, actions"
                                                ></v-skeleton-loader>
                                            </v-col>
                                            <contest-participation-card
                                                v-for="(contest, i) in sortedContests"
                                                v-else
                                                :key="`contest_card_${i}`"
                                                :contest-dto="contest"
                                                :statuses-to-display=statusFilters
                                            >
                                            </contest-participation-card>
                                        </v-row>
                                    </v-container>
                                </v-card>
                            </v-tab-item>
                            <v-tab-item>
                                <v-card flat>
                                    <v-card-text class="px-0 py-0 statistics-container">
                                        <v-data-table
                                            :headers="statisticsTableHeaders"
                                            :items="statisticsRows"
                                            :items-per-page=100
                                            hide-default-footer
                                            hide-default-header
                                            mobile-breakpoint="400"
                                        >
                                            <template v-slot:item.col2="{ item }">
                                                <span v-if="'Disconnects/Surrenders' === item.col1"
                                                      v-title
                                                      title="The high ratio might result in <br/>permanent account ban"
                                                      :class="{
                                                        'box-dc':true,
                                                        'low-dc': item.col2>5,
                                                        'medium-dc': item.col2>10,
                                                        'high-dc': item.col2>15,
                                                        'very-high-dc': item.col2>20,
                                                    }"
                                                >
                                                    {{ item.col2 }}%
                                                </span>
                                                <span v-else>{{ item.col2 }}</span>
                                            </template>
                                        </v-data-table>
                                    </v-card-text>
                                </v-card>
                            </v-tab-item>
                            <v-tab-item>
                                <v-card flat>
                                    <virtual-wallet-overview>
                                    </virtual-wallet-overview>
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>

import {apiServiceVue} from "../services/apiService.vue";
import PageFiltersSection from "../components/page-filters-section";
import DialogBox from "../components/dialog-box";
import QrcodePopup from "../components/profile/qrcode-popup";
import {timestampToHoursMinutesSeconds} from "@/helpers/timeHelper";
import ContestParticipationCard from "../components/contests/contest-participation-card";
import UpdateProfilePopup from "../components/profile/update-profile-popup";
import {PARTICIPATION_STATUS_DISPLAY} from "@/classes/contests/ContestParticipationDTO";
import _ from 'lodash';
import AccountService from "@/services/accountService";
import VirtualWalletOverview from "@/components/virtual-wallet/virtual-wallet-overview";
import ConnectWallet from "@/views/ConnectWallet";
import LeaderboardRewardInfo from "@/components/profile/leaderboard-reward-info";


export default {
    components: {
        LeaderboardRewardInfo,
        ConnectWallet,
        VirtualWalletOverview,
        UpdateProfilePopup,
        ContestParticipationCard,
        QrcodePopup,
        DialogBox,
        PageFiltersSection
    },

    data() {
        return {
            SHOW_V_WALLET: true,
            CONTEST_STATUSES: PARTICIPATION_STATUS_DISPLAY,
            /** @type {AccountService} */
            accountService: undefined,
            account: {},
            mainTab: 0,
            statisticsTableHeaders: [
                {text: 'Type', value: 'col1', align: 'center', divider: true},
                {text: 'Amount', value: 'col2', align: 'center', divider: true},
            ],
            statisticsRows: [],
            availableContests: [],
            loadingContests: true,
            loadingTags: true,
            allTags: [],
            selectedTags: [],
            selectedStatuses: ["IN_PROGRESS", "ACHIEVED", "CLAIMABLE"],
            statusFilters: undefined,
        };
    },
    mounted: function () {
        this.accountService = new AccountService();

        if (this.isWalletConnected) {
            this.init();
        } else {
            window.addEventListener('user-wallet-loaded', () => {
                this.init();
            });
        }
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        web3Balance() {
            return this.web3.balance;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        allStatuses() {
            return Object.values(PARTICIPATION_STATUS_DISPLAY)
                .map(value => ({value: value, text: _.chain(value).lowerCase().startCase().value()}));
        },
        sortedContests() {
            const contests = this.loadingContests ? [] : this.availableContests;
            return contests.sort((contestA, contestB) => contestB.id - contestA.id)
        },
    },
    methods: {
        init() {
            this.accountService.fetchProfile(this.web3.address)
                .then(data => {
                    this.account = data;
                    this.statisticsRows = [
                        {
                            col1: 'Playtime',
                            col2: timestampToHoursMinutesSeconds(this.account.playtime || 0),
                        },
                        {
                            col1: 'Played Games',
                            col2: this.account.wins + this.account.loses,
                        },
                        {
                            col1: 'Wins',
                            col2: this.account.wins,
                        },
                        {
                            col1: 'Loses',
                            col2: this.account.loses - this.account.disconnections,
                        },
                        {
                            col1: 'Disconnects/Surrenders',
                            col2: this.calculateDisconnectRatio(this.account),
                        },
                        {
                            col1: 'Win ratio',
                            col2: isNaN(this.account.winRatio) ? '-' : (this.account.winRatio * 100 + '%'),
                        },
                    ]
                })
                .catch((e) => {
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Fetch profile failed'
                    });
                });

            apiServiceVue.get(process.env.VUE_APP_ACCOUNT_URL + 'wallet/' + this.web3.address + '/contests/tags/')
                .then(res => {
                    this.allTags = res.data.tags;
                })
                .catch((e) => {
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Fetch tags failed'
                    });
                });

            this.fetchContests();
            this.loadLastTab();
        },
        calculateDisconnectRatio(account) {
            return Math.round(account.disconnections / (account.wins + account.loses) * 100);
        },
        loadLastTab() {
            if (null !== localStorage.getItem('ProfileSelectedTab')) {
                this.mainTab = Number(localStorage.getItem('ProfileSelectedTab'));
            }
        },
        saveLastTab() {
            localStorage.setItem('ProfileSelectedTab', this.mainTab)
        },
        searchContests() {
            this.loadingContests = true;
            this.fetchContests();
        },
        fetchContests() {
            this.loadingContests = true;
            this.updateStatusFilters();
            apiServiceVue.get(process.env.VUE_APP_ACCOUNT_URL + 'contests/wallet/' + this.web3.address, {tags: this.selectedTags})
                .then(res => {
                    this.availableContests = res.data;
                })
                .catch((e) => {
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Fetch contests failed'
                    });
                }).finally(_ => {
                this.loadingContests = false;
            });
        },
        updateStatusFilters() {
            this.statusFilters = this.selectedStatuses?.length > 0 ? Array.from(this.selectedStatuses) : undefined;
        },
    },
    watch: {
        mainTab() {
            this.saveLastTab()
        },
    }
}
</script>

<style lang="scss" scoped>
.box-dc {
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 10px;

    &.low-dc {
        background: green;
    }

    &.medium-dc {
        color: #000;
        background: yellow;
    }

    &.high-dc {
        background: red;
    }

    &.very-high-dc {
        color: #fff;
        background: darkred;
    }
}

.v-application .page-content {
    margin-top: 90px;
}

::v-deep .top-section {
    height: 100px;
}

::v-deep .balance-indicator {
    padding: 0;
}

::v-deep .balance-indicator span {
    font-size: 18px;
}

.profile {
    .v-tab {
        background: #4c356c;
        color: #fff;
        border-color: yellow !important;
    }

    .v-tab--active {
        background: #5b3f80;
    }
}

.v-tabs-slider-wrapper {
    color: red !important;
}

.tab-icon {
    width: 24px;
    height: 24px;
}

.tab-description {
    width: 100%;
    height: 60px;
    line-height: 60px;
    text-align: center;
    display: block;
    background: #3c0851;
}

.v-list {
    background-color: var(--v-buttonBg-base);
}
</style>

<style lang="scss">
.v-tabs-slider-wrapper {
    // Bar indicating active tab
    color: var(--v-golden-base);
}

.v-item-group.theme--dark.v-slide-group {
    background: none !important;
}

.theme--dark.v-data-table {
    background: #221529;
}

.v-expansion-panel-header {
    background-color: var(--v-cardBg-base);
    border: 2px solid var(--v-frames-base);
    border-radius: 8px;
}

.v-expansion-panel-header--active {
    border-bottom: none;
    border-radius: 8px 8px 0 0;
}

.v-expansion-panel-content__wrap {
    background-color: var(--v-background-base);
    border: 2px solid var(--v-frames-base);
    border-top: none;
    border-radius: 0 0 8px 8px;
}
</style>

